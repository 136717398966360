import * as React from 'react'
import * as clsx from 'clsx'
import { useFormContext, useWatch } from 'react-hook-form'
import { CSSTransition } from 'react-transition-group'
import { SaveButton } from '@components/controls/save-button'
import { PolishCompanyDataProviderResult } from '@models/reservation'
import { getCompanyInformation, updateMainReservationData } from '@requests/reservation/reservation-requests'
import { useFormRequest } from '@hooks/use-form-request'
import { selectApiUrls } from '@store/selectors/app-data-selectors'
import { useDispatch, useSelector } from 'react-redux'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { CountrySelect } from '@components/controls/country-select'
import { FormError } from '@components/controls/form-error'
import { useDevicesSize } from '@hooks/use-devices-size'
import { SummaryInvoiceTaxIdInput } from '@modules/summary-step/invoice/summary-invoice-tax-id-input'

interface Props {
  isDisabled: boolean
}

export const SummaryInvoiceTaxId = ({ isDisabled }: Props): JSX.Element => {
  const { isMobile } = useDevicesSize()
  const { nip_search } = useSelector(selectApiUrls)

  const dispatch = useDispatch()

  const { control, setError, clearErrors, setValue } = useFormContext<SummaryStepFormInputs>()

  const { handleAction, isLoading } = useFormRequest(setError)

  const [invoice, nip, country] = useWatch({ control, name: ['invoice', 'invoice_nip', 'invoice_country.value'] })

  const handleTaxIdSearch = async () => {
    clearErrors()
    const { data } = await handleAction<PolishCompanyDataProviderResult>(() => getCompanyInformation(nip_search, nip))
    if (!data) return

    const invoiceData = {
      invoice: true,
      invoice_nip: data.tax_id,
      invoice_city: data.city,
      invoice_company: data.name,
      invoice_postcode: data.postal_code,
      invoice_street: data.street_address,
      invoice_country: { value: 'PL', label: 'Polska' },
    }

    Object.entries(invoiceData).forEach(([key, value]: [keyof SummaryStepFormInputs, string]) => {
      setValue(key, value)
    })

    handleAction(() => dispatch(updateMainReservationData({ ...invoiceData, invoice_country: 'PL' })))
  }

  return (
    <CSSTransition timeout={{ enter: 0, exit: 200 }} classNames="fade" in={invoice} unmountOnExit>
      <div>
        <div className="d-flex flex-sm-row flex-column gap-sm-3 align-items-center">
          <CountrySelect
            name="invoice_country"
            withLabel={isMobile}
            isDisabled={isDisabled}
            wrapperProps={{
              style: { minWidth: 90 },
              className: clsx('mt-3 btn-tall col-12 col-sm-auto', { 'opacity-50': isDisabled }),
            }}
          />
          <SummaryInvoiceTaxIdInput isDisabled={isDisabled} />
          {country === 'PL' && (
            <SaveButton
              isLoading={isLoading}
              text="Pobierz z GUS"
              loadingText="Pobieranie"
              disabled={isDisabled}
              iconClassName=""
              className="bg-dark-gradient text-white mt-3 w-responsiveness-btn btn-tall text-nowrap"
              onClick={handleTaxIdSearch}
            />
          )}
        </div>
        <FormError inputName="invoice_nip" />
      </div>
    </CSSTransition>
  )
}
