import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'

interface Props {
  onEditClick: () => void
}

export const SummaryInvoiceData = ({ onEditClick }: Props): JSX.Element => {
  const { watch } = useFormContext<SummaryStepFormInputs>()

  const { invoice_city, invoice_nip, invoice_company, invoice_country, invoice_postcode, invoice_street } = watch()

  const isPolishInvoice = invoice_country?.value === 'PL'

  return (
    <div className="col-md-10 d-flex mt-3">
      <span className="fw-semi-bold col-md-6 d-none d-xl-block text-darker-gray">Informacje o firmie:</span>
      <div>
        <div className="text-muted d-flex flex-column text-xl-start font-size-md">
          <div>{invoice_company}</div>
          <div>{invoice_street}</div>
          <div>
            {invoice_postcode && <span>{invoice_postcode}, </span>}
            {invoice_city && <span>{invoice_city}, </span>}
            {invoice_country?.label}
          </div>
          <div>
            {isPolishInvoice ? 'NIP:' : ''} {invoice_nip}
          </div>
        </div>
        <IconWithText
          iconClassName="uil-edit-alt me-2 font-size-xxl"
          text="Edytuj dane firmy"
          wrapperClassName="mt-4 text-darker-gray cursor-pointer"
          onClick={onEditClick}
        />
      </div>
    </div>
  )
}
