import * as React from 'react'
import * as clsx from 'clsx'
import { useFormContext, useWatch } from 'react-hook-form'
import { CustomFormGroup } from '@components/controls/form-controls/custom-form-group'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'

interface Props {
  isDisabled: boolean
}

export const SummaryInvoiceTaxIdInput = ({ isDisabled }: Props): JSX.Element => {
  const { control, setValue, setFocus } = useFormContext<SummaryStepFormInputs>()

  const country = useWatch({ control, name: 'invoice_country.value' })
  const isPolishCountry = country === 'PL'

  useDidUpdateEffect(() => {
    setValue('invoice_nip', isPolishCountry ? '' : country)
    setFocus('invoice_nip')
  }, [country])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\p{Letter}\d/.]+/gu, '').toUpperCase()
    setValue('invoice_nip', `${value.startsWith(country) || isPolishCountry ? '' : country}${value}`)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && event.currentTarget.value === country) event.preventDefault()
  }

  return (
    <CustomFormGroup
      hideError
      inputName="invoice_nip"
      formControlProps={{
        placeholder: isPolishCountry ? 'Wpisz NIP' : '',
        className: 'btn-tall col-12 col-sm-5',
        disabled: isDisabled,
        onInput: handleChange,
        onKeyDown: handleKeyDown,
      }}
      formGroupProps={{ className: clsx('col-12 col-sm-5', { 'opacity-50': isDisabled }) }}
    />
  )
}
