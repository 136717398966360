import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { SummaryCard } from '@modules/summary-step/summary-card'
import { CSSTransition } from 'react-transition-group'
import { updateMainReservationData } from '@requests/reservation/reservation-requests'
import { useFormRequest } from '@hooks/use-form-request'
import { useDispatch, useSelector } from 'react-redux'
import { SummaryStepFormInputs } from '@modules/summary-step/summary-step'
import { SummaryInvoiceData } from '@modules/summary-step/invoice/summary-invoice-data'
import { SummaryInvoiceEditForm } from '@modules/summary-step/invoice/summary-invoice-edit-form'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { selectReservation } from '@store/selectors/reservation-selectors'
import { updateReservation } from '@store/actions/reservation-actions'
import { SummaryInvoiceTaxId } from '@modules/summary-step/invoice/summary-invoice-tax-id'

export const SummaryInvoice = (): JSX.Element => {
  const [isCompanyDataEditEnabled, setIsCompanyDataEditEnabled] = React.useState(true)

  const reservation = useSelector(selectReservation)
  const dispatch = useDispatch()

  const { control, setError } = useFormContext<SummaryStepFormInputs>()
  const { handleAction } = useFormRequest(setError)

  const invoice = useWatch({ control, name: 'invoice' })

  useDidUpdateEffect(() => {
    dispatch(updateReservation({ invoice }))

    if (invoice) return
    handleAction(() => dispatch(updateMainReservationData({ invoice })))
  }, [invoice])

  React.useEffect(() => {
    const { invoice, invoice_city, invoice_company, invoice_country, invoice_nip, invoice_postcode, invoice_street } =
      reservation
    setIsCompanyDataEditEnabled(
      ![invoice, invoice_city, invoice_company, invoice_country, invoice_nip, invoice_postcode, invoice_street]
        .map(Boolean)
        .every(Boolean),
    )
  }, [
    reservation.invoice,
    reservation.invoice_city,
    reservation.invoice_company,
    reservation.invoice_country,
    reservation.invoice_nip,
    reservation.invoice_postcode,
    reservation.invoice_street,
  ])

  const handleIsCompanyDataEditEnabledChange = value => () => {
    setIsCompanyDataEditEnabled(value)
  }

  return (
    <SummaryCard
      subtitle="Niepodanie numeru NIP jest jednoznaczne z brakiem możliwości wystawienia FV w późniejszym terminie."
      title={<strong className="text-primary font-size-xl">Dane do faktury</strong>}
      childrenWrapperClassName={'col-12 col-md-7 mt-2 mt-md-0'}
      afterContent={
        <CSSTransition timeout={{ enter: 0, exit: 200 }} classNames="fade" in={invoice} unmountOnExit>
          {isCompanyDataEditEnabled ? (
            <SummaryInvoiceEditForm
              onCancel={handleIsCompanyDataEditEnabledChange(false)}
              onSave={handleIsCompanyDataEditEnabledChange(false)}
            />
          ) : (
            <SummaryInvoiceData onEditClick={handleIsCompanyDataEditEnabledChange(true)} />
          )}
        </CSSTransition>
      }
    >
      <Checkbox
        id="invoice"
        name="invoice"
        label="Chcę otrzymać fakturę VAT"
        className="text-darker-gray d-inline-flex align-items-center fw-bold"
      />
      <SummaryInvoiceTaxId isDisabled={!isCompanyDataEditEnabled} />
    </SummaryCard>
  )
}
